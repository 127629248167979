@import "./fonts.scss";

body{
    font-family: 'Netto OT',sans-serif;
    font-weight: 400;
}
$netto: "Netto";


:root {    
    --themecolor: #1780CD;  
    --themefontColor: #ffffff;
  }


// Used Color Variables

$themecolor: #1780CD;
$themefontColor: #ffffff;
@mixin gradientcolor{
    background-color: var(--themecolor);
  }
  @mixin shadow1 {    box-shadow: 0px 3px 6px 0 #0000001a;}




.btn{
    // text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700!important;
    min-width: 100px;
    height: 35px;
    line-height: 1;
}
.btn-outline-secondary {
    color: #1a2126;
    border-color: #d9d9d9;
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d69;
    border-color: #6c757d4a;
}
.btn-outline-primary{
    color: #1a2126;
    &:hover{
        color: #fff;
    }
}
.ql-editor{
    padding: 0!important;
}

.form-control {
    font-size: 0.8rem;
}

